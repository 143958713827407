import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {

  obtenerPrecioProducto(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products/get/price" + Session.getToken();
    return instance.post(url,params);        
  },

  obtenerPrecioServicio(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "services/get/price" + Session.getToken();
    return instance.post(url,params);        
  },  

  obtenerPrecioProductoCompuesto(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-compound/get/price" + Session.getToken();
    return instance.post(url,params);            
  },

  obtenerEquivalencesAvailable(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-unit-measurement/filter/equivalenceAvailable" + Session.getToken();
    return instance.post(url,params);            
  },
  recalculePriceByUnitMeasurementAlternative(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-unit-measurement/recalcule/price" + Session.getToken();
    return instance.post(url,params);                
  },

  obtenerProductos(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products/find/control/controlByFilter" + Session.getToken();
    return instance.post(url,params);        
  },  
  mostrarProducto(id, data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products/find/control/controlShow/" + id + Session.getToken();
    return instance.post(url,params);        
  },    
  
}

export default servicesAPI;
